<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">城市静态交通解决方案</div>
        <div class="jieshao">
          城市静态交通解决方案将行业经验与前沿技术完美融合，为城市静态交通治理提供“投、融、建、管、运”五位一体支撑，助力城市实现“停车上云、云上停车”，提供“治理一张图、运营一张网”的高质量监管、运营及服务，让城市快速进入一个更加智能、更加便携、更具有持续运营能力和服务能力的全场景智慧停车时代，有效缓解停车供需矛盾，进一步规范停车秩序，优化出行环境，构建停车生态，全面推动城市停车产业数字化转型，打造“以静制动、动静融合、良性循环、一体化运行”的智慧停车城市。
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统组成</span
          >
        </div>
        <div style="width: 89%;margin-bottom:64px">
          <ul
            v-for="(item, index) in dataBox"
            :key="index"
          >
            <li
              style="
                font-size: 20px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                display: flex;
                margin-top: 24px;
              "
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
        <div>
          <img
            style="width: 1186px;"
            src="./img/静态1.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >系统架构</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/静态2.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >方案优势</span
          >
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/静态3.png"
            alt=""
          />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBox: [
          {
            text: "城市智慧停车管理服务平台",
          },
          {
            text: "互联网+停车服务系统",
          },
          {
            text: "路内停车管理系统",
          },
          {
            text: "城市停车诱导系统",
          },
          {
            text: "智能立体停车库管理系统",
          },
          {
            text: "智能BMS管理功能",
          },
          {
            text: "违法停车一体化抓拍系统",
          },
        ],
    };
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  /* height: 1404px; */
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiaobiaoqian {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -78.5%;
}
.xiaobiaoqian9 {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -67%;
}
li::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin: 1%;
}
</style>